import RegulationDocs from "../pages/Documents/RegulationsDocs";
import SamplesStatemans from "../pages/Documents/SamplesStatemans";
import TradeUnionDocs from "../pages/Documents/TradeUnionDocs";

export const documentsData = [
    {
        id:1,
        title:'edit_teamMembers',
        content:<RegulationDocs/>
    },

    {
        id:2,
        title:'edit_teamMembers',
        content:<TradeUnionDocs/>
    },

    {
        id:3,
        title:'edit_teamMembers',
        content:<SamplesStatemans/>
    }
]

export const regulationsDocs = [
    {
        id:1,
        title:'edit_teamMembers',
        link:"https://pon.org.ua/statut/"
    },
    {
        id:2,
        title:'edit_teamMembers',
        link:"https://pon.org.ua/statut/"
    },

    {
        id:3,
        title:'edit_teamMembers',
        link:"https://pon.org.ua/statut/"
    }
]

export const tradeUnionDocs = [
    {
        id:1,
        title:'edit_teamMembers',
        link:"https://pon.org.ua/statut/"
    },
    {
        id:2,
        title:'edit_teamMembers',
        link:"https://pon.org.ua/statut/"
    },

    {
        title:'edit_teamMembers',
        link:"https://pon.org.ua/statut/"
    }
]

export const samplesStatemans = [
    {
        id:1,
        title:'edit_teamMembers',
        link:"https://pon.org.ua/statut/"
    },
    {
        id:2,
        title:'edit_teamMembers',
        link:"https://pon.org.ua/statut/"
    },

    {
        id:3,
        title:'edit_teamMembers',
        link:"https://pon.org.ua/statut/"
    },

    {
        id:4,
        title:'edit_teamMembers',
        link:"https://pon.org.ua/statut/"
    },
    {
        id:5,
        title:'edit_teamMembers',
        link:"https://pon.org.ua/statut/"
    },

    {
        id:6,
        title:'edit_teamMembers',
        link:"https://pon.org.ua/statut/"
    }
]