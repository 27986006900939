

const ProfkomTeamMember = ({teamMemberItemData}) => {


    return(
        <div>fdfd</div>
    )
}

export default ProfkomTeamMember