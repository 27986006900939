const Poll = () => {



    return( <>
    <h1>Poll</h1>
 
    </>);
 }
 
 export default Poll