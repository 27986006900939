import SamplesStatemans from "../pages/Documents/SamplesStatemans";
import TradeUnionDocs from "../pages/Documents/TradeUnionDocs";
import ChangeTeamMembers from "../components/adminComponents/ChangeTeamMembers";

export const superAdminData = [
    {
        id:1,
        title:'edit_teamMembers',
        content:<ChangeTeamMembers/>
    },

    {
        id:2,
        title:'edit_teamMembers',
        content:<TradeUnionDocs/>
    },

    {
        id:3,
        title:'edit_teamMembers',
        content:<SamplesStatemans/>
    }
]