

const ProfkomStructure = () => {
    return(
        <div className="d-flex justify-content-center flex-column">
            <h1 className="text-center">Стурктура профкому</h1>
            <img src='http://profkom.ua/wp-content/uploads/2015/06/Struktura-profspilkovoyi-organizatsiyi.png' className='img-fluid shadow-4' alt='...' />
        </div>
    );
}

export default ProfkomStructure;