import {GiEarthAfricaEurope} from 'react-icons/gi'

export const programs = [
    {
        id:1,
        titleUA:'ISIC',
        titleEN:'ISIC',
        icon:<GiEarthAfricaEurope size={40}/>
    },

    {
        id:2,
        titleUA:'ISIC',
        titleEN:'ISIC',
        icon:<GiEarthAfricaEurope size={40}/>
    },

    {
        id:3,
        titleUA:'ISIC',
        titleEN:'ISIC',
        icon:<GiEarthAfricaEurope size={40}/>
    },

    {
        id:4,
        titleUA:'ISIC',
        titleEN:'ISIC',
        icon:<GiEarthAfricaEurope size={40}/>
    },

    {
        id:5,
        titleUA:'ISIC',
        titleEN:'ISIC',
        icon:<GiEarthAfricaEurope size={40}/>
    }
]