export const indicators = [
    {
        id:1,
        titleUA:"ПЕРЕГЛЯДІВ",
        titleEN:"REVIEWS",
        count:14000
    },

    {
        id:2,
        titleUA:"ВЛАСНИКІВ КАРТИ ISIC",
        titleEN:"ISIC CARDHOLDERS",
        count:248
    },

    {
        id:3,
        titleUA:"ЧЛЕНІВ ОРГАНІЗАЦІЇ",
        titleEN:"MEMBERS OF THE ORGANIZATION",
        count:8912
    },

    {
        id:4,
        titleUA:"ОФОРМЛЕНИХ СУБСИДІЙ",
        titleEN:"SUBSIDIES APPROVED",
        count:598
    }
]